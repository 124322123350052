import React, { Component } from 'react';
import {connect} from 'react-redux';
import ReactTable from "react-table";
import {FuseAnimateGroup, FusePageSimple} from '../../../../../@fuse';
import {withStyles, Icon, Typography, Tooltip, IconButton, TextField} from '@material-ui/core';
import * as actions from '../../../../store/crm/actions'
import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import {withTranslation} from 'react-i18next';
import {filterAll} from '../../../../components/Functions/FilterAll';
import {getCompany} from '../../../Auth/Auth';
import axios from 'axios';

const styles = theme => ({
    layoutRoot: {},
    root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    },
    table: {
    minWidth: 700,
    },
    row: {
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
    },
    },
});

class ClosedCustomers extends Component {
    state = {
        days: 60,
        filterValue: '',
        filteredCustomers: [],
        insertedFilteredCustomers: false,
        AllCustomers: []
    }

    componentDidMount() {
        this.GetClosedCustomers(this.state.days);
    }

    handleGlobalFilterChange = event => {
        this.setState({ ...this.state, filterValue: event.target.value, filteredCustomers: filterAll(this.state.AllCustomers, event.target.value) });
    };
    
    GetClosedCustomers = (days) => {        
        this.setState({ ...this.state, days });
        if(days > 0){
            axios.get('customer/GetClosedCustomers?Days=' + days
            ).then(res => {
                this.setState({AllCustomers: res.data, filteredCustomers: this.state.filterValue ===  '' ? res.data : filterAll(res.data, this.state.filterValue)});
            }).catch((error) => {
                console.log(error.response);
            });
        }
    }
    
    render() {
        const {classes, t} = this.props;
        const customers = this.state.filteredCustomers;
        return (
                <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("CLOSED_CUSTOMERS")}</Typography>
                            </div>
                            <Typography variant="h6">{t("CLOSED_CUSTOMERS")}</Typography>
                        </div>
                        <div className="flex items-end">
                            <TextField
                                label={t("MAXDAY") + " " + t("CLOSED")}
                                className={classes.textField + " mr-12"}
                                value={this.state.days}
                                onChange={(event) => this.GetClosedCustomers(event.target.value)}
                                // onBlur={this.GetClosedCustomers2(this.state.days)}
                                margin="normal"
                            />
                            <TextField
                                label={t("GLOBALSEARCH")}
                                className={classes.textField}
                                value={this.state.filterValue}
                                onChange={this.handleGlobalFilterChange}
                                margin="normal"
                            />
                        </div>
                    </div>
                }
                content={customers && customers.length > 0 ?
                    <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                        <div className="w-full">
                            <ReactTable
                                className="-striped -highlight border-0"
                                data={customers}
                                columns={[
                                    {
                                        Header    : <Icon>lock</Icon>,
                                        accessor  : "levStop",
                                        className : "justify-center",
                                        width     : 50,
                                        Cell: row => (row.value ? <Icon style={{color: 'red'}}>lock</Icon> : <Icon style={{color: 'green'}}>shopping_cart</Icon>)
                                    },
                                    {
                                        Header    : "Debnr",
                                        accessor  : "debnr",
                                        filterable: true,
                                        className : "font-bold justify-center",
                                        width     : 100,
                                        Cell: row => (<RouteToDebnr row={row} />)
                                    },
                                    {
                                        Header    : t("NAME"),
                                        accessor  : "name",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                    {
                                        Header    : t("PRELEVEMENT"),
                                        accessor  : "betaalConditie",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                    {
                                        Header    : t("MAXDAY"),
                                        accessor  : "bettermijn",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                    {
                                        Header    : t("DAYS") + " " + t("CLOSED"),
                                        accessor  : "created",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={20}
                                noDataText="No customers found"
                            /> 
                        </div>
                    </FuseAnimateGroup>
                : <Spinner /> }
                />
        )
    }
}

export default connect()(withStyles(styles, {withTheme: true})(withTranslation()(ClosedCustomers)));