import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../../store/crm/actions';
import * as decoActions from '../../../../store/hbxdeco/actions';
import { withTranslation } from 'react-i18next';
import { checkRole, getAzureId, getCompany, getEmail, getUsername } from '../../../Auth/Auth';

import LineChart from '../../../../components/UI/Charts/RctJSCharts/LineChart/LineChart';
import Spinner from '../../../../components/UI/Spinner/Spinner';

import {
    Card, IconButton, TextField, Divider, Paper, Button, withStyles, Icon, Tooltip,
    List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableHead, TableRow,
    Typography, ListItemAvatar, Avatar, MenuItem, FormControl, Select, InputLabel, Toolbar, AppBar, Accordion, AccordionSummary, AccordionDetails, Menu
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Scrollbars } from 'react-custom-scrollbars';
import themesConfig from '../../../../fuse-configs/themesConfig';

import CustomerWeblogVisits from './CustomerCharts/CustomerWeblogVisits';
import CustomerConfirmationDialog from './CustomerConfirmationDialog';

import { FuseAnimateGroup, FusePageSimple } from '../../../../../@fuse';

import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import moment from 'moment';
import CustomerRouteCalendar from './CustomerComponents/CustomerRouteCalendar';
import LensIcon from '@material-ui/icons/LensRounded';
import BackorderOverview from './CustomerComponents/BackorderOverview';
import Axios from 'axios';

const styles = theme => ({
    bgHasDay: {
        backgroundColor: '#dff0d8',
    },
    orangeBackground: {
        backgroundColor: orange[800],
    },
    blueBackground: {
        backgroundColor: '#3C4252',
    },
    warningIcon: {
        color: '#ffc107'
    },
    successIcon: {
        color: '#27c24c'
    },
    successButton: {
        color: '#fff',
        backgroundColor: green[600],
        '&:hover': {
            color: '#fff',
            backgroundColor: green[700]
        }
    },
    dangerButton: {
        color: '#fff',
        backgroundColor: '#ee3939',
        '&:hover': {
            backgroundColor: red[700]
        }
    },
    infoButton: {
        color: '#fff',
        backgroundColor: lightBlue[500],
        '&:hover': {
            backgroundColor: lightBlue[700]
        }
    },
    aColor: {
        color: '#ffffff !important'
    },
    turnoverBadge: {
        color: '#fff',
        display: 'inline-block',
        textAlign: 'center',
        padding: '4px 8px',
        borderRadius: '16px',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    currentYearBackground: {
        backgroundColor: '#3C4252'
    },
    lastYearBackground: {
        backgroundColor: '#ef6c00'
    },
    reservationsBackground: {
        backgroundColor: '#eb6460'
    },
    nextYearReservationsBackground: {
        backgroundColor: 'rgb(30,156,156)'
    },
})

class Customer extends Component {

    state = {
        comment: {
            title: '',
            memo: '',
            type: 1,
            sent: false,
        },
        routeDate: new Date(),
        submittedRoute: false,
        popoverAnchorEl: null,
        showBackorderOverview: false,
        backorderMenuAnchorEl: null,
        changingEcommerceCustomerCode: false,
        currentCompanyId: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.debnr !== this.props.match.params.debnr) {
            this.loadCustomerData(nextProps.match.params.debnr);
        }
    }

    componentDidMount() {
        this.loadCustomerData(this.props.match.params.debnr);
    }

    loadCustomerData = debnr => {
        this.props.onFetchCustomer(debnr);
        if (getCompany() === 4) {
            this.props.onFetchHbxDecoDebiteurTurnover(debnr);
        } else {
            this.props.onFetchTurnover(debnr);
        }
        this.props.onFetchDeparture(debnr);
        this.props.onFetchInvoices(debnr);
        this.props.onFetchTodaysTurnover(debnr);
        this.props.onFetchUnpaid(debnr);
        this.props.onFetchComments(debnr);
        this.props.onFetchWeblogVisits(debnr);
    }

    handleCommentSubmit = () => {
        this.props.onSubmitComment(this.props.match.params.debnr, { ...this.state.comment });
        this.setState({
            comment: {
                title: '',
                memo: '',
                type: 1,
                sent: true,
            }
        });
        setTimeout(() => this.setState({ comment: { ...this.state.comment, sent: false } }), 3000);
    }

    handleDownloadReservationExcel = () => {
        this.props.onDownloadReservationExcel(this.props.match.params.debnr);
    }
    handleMailReservationExcel = (mailSalesmanOnly) => {
        this.props.onMailReservationExcel({ debnr: this.props.customer.debnr, email: this.props.customer.email }, mailSalesmanOnly);
    }
    updateComment = (event) => {
        this.setState({
            comment: {
                ...this.state.comment,
                [event.target.name]: event.target.value
            }
        });
    }

    handleSubmitOpenOrClose = (shouldUpdateAllInFinancialGroup) => {
        const { debnr, levStop, finDebnr } = this.props.customer;
        // console.log(shouldUpdateAllInFinancialGroup);
        this.props.onSubmitOpenOrClose(debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, getEmail(), getUsername());
        const userLog = {
            azureId: getAzureId(),
            type: 'button',
            actionName: levStop ? 'OpenDebnr' : 'CloseDebnr',
            extraInfo: 'DEBNR : ' + debnr
        }
        this.props.onCreateUserLog(userLog);
    }

    handleRouteDateChange = (date) => {
        this.setState({ routeDate: date });
    }

    onSubmitOpenCustomerRoute = () => {
        console.log(this.state.routeDate.toISOString(), this.props.customer.debnr);
        this.props.onSubmitOpenCustomerRoute(this.props.customer.debnr, this.state.routeDate.toISOString(), this.submitOpenCustomerRouteCallback);
        const userLog = {
            azureId: getAzureId(),
            type: 'button',
            actionName: 'OpenRoute',
            extraInfo: 'DEBNR : ' + this.props.customer.debnr
        }
        this.props.onCreateUserLog(userLog);
    }

    submitOpenCustomerRouteCallback = () => {
        this.setState({ submittedRoute: true }, () => {
            setTimeout(() => {
                this.setState({ submittedRoute: false });
            }, 3000)
        })
    }

    handleWebshopClick = () => {
        const { debnr } = this.props.customer;
        // console.log(shouldUpdateAllInFinancialGroup);
        const userLog = {
            azureId: getAzureId(),
            type: 'button',
            actionName: 'WebshopVisit',
            extraInfo: 'DEBNR : ' + debnr
        }
        this.props.onCreateUserLog(userLog);
        return true;
    }

    navigateToLogout = () => {
        if (this.state.currentCompanyId !== null) {
            if (this.state.currentCompanyId === 1) {
                window.open('https://pilot.vdplas.com/logout', '_blank');
            } else if (this.state.currentCompanyId === 8) {
                window.open('https://store.roobos.nl/logout', '_blank');
            }
            this.setState({
                currentCompanyId: null
            })
        }
    }

    handlePilotShopClick = () => {
        this.setState({ changingEcommerceCustomerCode: true, currentCompanyId: null });
        Axios.get('customer/UpdateSalesmanCustomerCode/' + this.props.customer.debnr)
            .then(res => {
                const companyId = getCompany();
                setTimeout(() => {
                    this.setState({ changingEcommerceCustomerCode: false, currentCompanyId: companyId });
                }, 5000)
            })
            .catch(err => {
                this.setState({ changingEcommerceCustomerCode: false, currentCompanyId: null });
                alert('Please contact n.vandermeij@floraltradegroup.com for further assistance');
            })
    }

    handlePopoverOpen = (event) => {
        this.setState({ popoverAnchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ popoverAnchorEl: null });
    };

    handleBackoverviewOpen = () => {
        this.setState({ showBackorderOverview: true });
    };

    handleBackoverviewClose = () => {
        this.setState({ showBackorderOverview: false });
    }

    handleStateToggle = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    handleBackorderMenuClose = () => {
        this.setState({ backorderMenuAnchorEl: null })
    }
    handleBackorderMenuOpen = (event) => {
        this.setState({ backorderMenuAnchorEl: event.currentTarget })
    }

    getCircleByScore = score => {
        switch (score) {
            case 0:
                return <LensIcon className="text-16" style={{ color: 'red', marginBottom: 2 }} />;
            case 1:
                return <LensIcon className="text-16" style={{ color: 'orange', marginBottom: 2 }} />;
            case 2:
                return <LensIcon className="text-16" style={{ color: 'yellow', marginBottom: 2 }} />;
            case 3:
                return <LensIcon className="text-16" style={{ color: 'green', marginBottom: 2 }} />;
            default:
                return <LensIcon className="text-16" style={{ color: 'red', marginBottom: 2 }} />;
        }
    }

    getTurnoverChartLabelByKey = key => {
        switch (key) {
            case 'lastYear': return 'Last year';
            case 'reservations': return 'This Year reservations';
            case 'nextYearReservations': return 'Next Year reservations';
            default: return 'Current Year';
        }
    }

    render() {
        const { classes, customer: customerData, t } = this.props;
        const themeColors = themesConfig.default.colors;

        const currentMonth = new Date().getMonth();

        const blocksWidth = customerData.credit && customerData.factTotaal !== 0 ? 'w-1/3' : 'w-1/2'

        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let turnoverDatasets = [];
        const debiteurTurnover = getCompany() === 4 ? this.props.decoDebiteurTurnover : this.props.turnover;
        if (debiteurTurnover) {
            turnoverDatasets = Object.keys(debiteurTurnover).map((key, index) => {
                const color = themeColors[index];
                const label = this.getTurnoverChartLabelByKey(key);
                return {
                    label: label,
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: color,
                    borderColor: color,
                    data: debiteurTurnover[key]
                };
            });
        }

        // Get reservation total
        const reservations = turnoverDatasets[2];
        let reservationsTotal = 0;
        if (reservations) {
            reservationsTotal = reservations.data.reduce((tempTotal, currentValue) => tempTotal + currentValue);
        }
        // console.log(reservationsTotal);

        let departureDataset = [];
        if (this.props.departure.history) {
            departureDataset = [{
                label: 'Departure',
                fill: false,
                lineTension: 0.5,
                backgroundColor: themeColors[0],
                borderColor: themeColors[0],
                pointRadius: 4,
                pointHoverRadius: 5,
                data: this.props.departure.history.timesOrdered
            }];
        }

        let table = null;
        if (this.props.invoices.length > 0) {
            table =
                (
                    <div className="table-responsive">
                        <Table>
                            <TableHead>
                                <TableRow hover>
                                    <TableCell>Invoice nr</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell className="text-right">Price</TableCell>
                                    <TableCell className="text-center">Paid</TableCell>
                                    <TableCell className="text-right">Days</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <Fragment>
                                    {this.props.invoices.map(i => {
                                        return (
                                            <TableRow hover key={i.number}>
                                                <TableCell>{i.number}</TableCell>
                                                <TableCell>{i.date.substr(0, 10)}</TableCell>
                                                <TableCell className="text-right">{i.amount}</TableCell>
                                                <TableCell className="text-right">{i.paid !== 0 ?
                                                    <>
                                                        <span>
                                                            {i.paid} {i.paid === i.amount ?
                                                                <Icon className="text-18 ml-12" style={{ color: 'green' }}>check</Icon> :
                                                                <Icon className="text-18" color="error">clear</Icon>
                                                            }
                                                        </span>
                                                    </> :
                                                    <Icon className="text-18" color="error">clear</Icon>}
                                                </TableCell>
                                                <TableCell className="text-right">{i.daysOverdue}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Fragment>
                            </TableBody>
                        </Table>
                    </div>
                )
        }

        let departureTable = null;
        if (this.props.departure.previousDays) {
            const departureDays = this.props.departure.previousDays;
            const availableDays = this.props.departure.availableDays;

            departureTable = (
                <div className="table-responsive">
                    <Table>
                        <TableHead>
                            <TableRow hover>
                                <TableCell>Week</TableCell>
                                <TableCell className={availableDays[2] ? classes.bgHasDay : null}>M</TableCell>
                                <TableCell className={availableDays[3] ? classes.bgHasDay : null}>T</TableCell>
                                <TableCell className={availableDays[4] ? classes.bgHasDay : null}>W</TableCell>
                                <TableCell className={availableDays[5] ? classes.bgHasDay : null}>T</TableCell>
                                <TableCell className={availableDays[6] ? classes.bgHasDay : null}>F</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Fragment>
                                {departureDays.map((val, i) => {
                                    return (
                                        <TableRow hover key={i}>
                                            <TableCell style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{val.Week}</TableCell>
                                            <TableCell>{val.Monday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Tuesday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Wednesday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Thursday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                            <TableCell>{val.Friday ? <Icon className="text-18" style={{ color: 'green' }}>check</Icon> : null}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </Fragment>
                        </TableBody>
                    </Table>
                </div>
            );
        }

        let customer = <Spinner />;
        if (customerData) {
            customer = (
                <List className="p-0 b-s">
                    {customerData.owner ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Icon>person</Icon></ListItemIcon>
                                <ListItemText primary={customerData.owner} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.email ?
                        <>
                            <ListItem>
                                <ListItemIcon><Icon>mail_outline</Icon></ListItemIcon>
                                <ListItemText primary={<><a href={"mailto:" + customerData.email}>{customerData.email}</a></>} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.phoneNumber || customerData.mobileNumber ?
                        <>
                            <ListItem>
                                <ListItemIcon><Icon>phone</Icon></ListItemIcon>
                                <ListItemText primary={<><a href={"tel:" + customerData.phoneNumber}>{customerData.phoneNumber}</a>
                                    {customerData.phoneNumber && customerData.mobileNumber ? <> - </> : null}
                                    <a href={"tel:" + customerData.mobileNumber}>{customerData.mobileNumber}</a></>} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.branche ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Icon>group</Icon></ListItemIcon>
                                <ListItemText primary={customerData.branche} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.btwNumber ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Icon>business</Icon></ListItemIcon>
                                <ListItemText primary={customerData.btwNumber} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.transTxt ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Icon>local_shipping</Icon></ListItemIcon>
                                <ListItemText primary={customerData.transTxt} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.accountManager ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Icon>how_to_reg</Icon></ListItemIcon>
                                <ListItemText primary={customerData.accountManager} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.creationDate ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={"Date of creation"}><Icon>event</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={moment(new Date(customerData.creationDate)).format('YYYY-MM-DD')} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.kostprijsDebiteur ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={"Kost prijs Debiteur"}><Icon>account_balance</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={customerData.kostprijsDebiteur} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.financielType ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={t('FINANCIELTYPE')}><Icon>business</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={customerData.financielType} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.commercialType ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={t('COMMERCIALTYPE')}><Icon>store</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={customerData.commercialType} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.betaalConditie || customerData.koopLimiet || customerData.kreditLimiet ? null : <Divider />}

                    {customerData.betaalConditie ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={t('PAYTERM')}><Icon>payment</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={customerData.betaalConditie} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.koopLimiet ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={t('BUYLIMIT')}><Icon>assignment</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={t('BUYLIMIT') + " : " + customerData.koopLimiet} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.kreditLimiet ?
                        <>
                            <ListItem button>
                                <ListItemIcon><Tooltip placement="left" title={t('CREDITLIMIT')}><Icon>account_balance</Icon></Tooltip></ListItemIcon>
                                <ListItemText primary={t('CREDITLIMIT') + " : " + customerData.kreditLimiet} />
                            </ListItem>
                            <Divider />
                        </>
                        : null}
                    {customerData.debTxt ?
                        <>
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: '#fafafa' }}
                                >
                                    <span style={{ minWidth: '56px' }}><Icon style={{ color: 'rgba(0, 0, 0, 0.54)' }}>comment</Icon></span>
                                    <Typography>{customerData.debTxt.substring(0, 30) + '...'}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component="span">
                                        {/* {customerData.debTxt.replace(/(\r\n|\n|\r)/gm, "\n")} */}
                                        {customerData.debTxt.split(/(\r\n|\n|\r)/gm).map(txt => <div key={txt}>{txt}</div>)}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </>
                        : null}
                </List>);
        }

        let ShopButtonClass = classes.dangerButton;
        if (customerData && customerData.inetCheck && !customerData.levStop) {
            ShopButtonClass = classes.successButton;
        }

        let openOrCloseButton = null;
        if (checkRole('iCRMFinancieel')) {
            openOrCloseButton = <CustomerConfirmationDialog
                handleSubmitOpenOrClose={this.handleSubmitOpenOrClose}
                amountInFinDebnr={customerData.amountInFinDebnr}
                finDebnr={customerData.finDebnr}
                title={!customerData.levStop ? t('CLOSE') : t('OPEN')}
                classes={"rounded-2 shadow-none border-1 px-12 mr-12" + !customerData.levStop ? classes.dangerButton : classes.successButton}
                icon={!customerData.levStop ? <Icon className={classes.rightIcon}>lock</Icon> : <Icon className={classes.rightIcon}>lock_open</Icon>} />;
        }
        return (
            <>
                {this.state.showBackorderOverview && <BackorderOverview debnr={customerData.debnr} handleClose={this.handleBackoverviewClose} />}
                <FusePageSimple header={customerData ?
                    <div className="flex flex-1 items-center justify-between p-8 sm:p-24">
                        <div className="w-1/2">
                            <Typography variant="h6">{customerData.name}</Typography>
                            <Typography color="textSecondary" className="text-12">{customerData.debnr} - {customerData.password}</Typography>
                            <Typography color="textSecondary" className="text-12">{customerData.address}, {customerData.zipcode} {customerData.city}</Typography>
                        </div>

                        <div className="flex flex-1 items-center justify-end px-12">
                            {/* {openOrCloseButton} */}
                            {reservationsTotal > 0 ?
                                <>
                                    <span>
                                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleBackorderMenuOpen}>
                                            Backorder menu
                                        </Button>
                                        <Menu
                                            open={Boolean(this.state.backorderMenuAnchorEl)}
                                            anchorEl={this.state.backorderMenuAnchorEl}
                                            onClose={this.handleBackorderMenuClose}
                                        >
                                            <MenuItem onClick={this.handleBackoverviewOpen} >Overzicht tonen</MenuItem>
                                            <MenuItem onClick={() => this.handleMailReservationExcel(false)}>Mail klant</MenuItem>
                                            <MenuItem onClick={() => this.handleMailReservationExcel(true)}>Mail verkoper</MenuItem>
                                        </Menu>
                                    </span>
                                </>
                                :
                                null
                            }
                            {/* <Tooltip title={t('DOWNLOADBACKORDERS')}>
                                    <Paper className={classes.orangeBackground + " w-full rounded-8 shadow-none border-1 text-white"} 
                                        elevation={1} 
                                        onClick={this.handleDownloadReservationExcel}
                                        style={{height: '100px', padding: '20px', textAlign: 'center', cursor: 'pointer'}}>
                                        <h3>&euro; {reservationsTotal ? reservationsTotal.toFixed(2) : 0 }</h3>                                      
                                        <small className="text-white">{t('DOWNLOADBACKORDERS')}</small>
                                    </Paper>
                                    </Tooltip> */}
                            {customerData.inetCheck ? openOrCloseButton : null}
                            {![1, 8].includes(getCompany()) ? (
                                <Tooltip title="Webshop 3">
                                    <a onClick={this.handleWebshopClick} href={`${customerData.webshopLink}/Account/LoginByUrl?user=${customerData.debnr}&pass=${customerData.password}&ReturnUrl=%2F${t('WEBSHOP_LANGUAGE')}`}
                                        className={classes.aColor + " pull-right"} target="_blank" rel="noopener noreferrer">
                                        <IconButton className={ShopButtonClass + " m-8"}>
                                            {!customerData.inetCheck ? <Icon className="text-16" color="action">not_interested</Icon> : null}
                                            {customerData.inetCheck && customerData.levStop ? <Icon className="text-16" color="action">lock</Icon> : null}
                                            {customerData.inetCheck && !customerData.levStop ? <Icon className="text-16" color="action">shopping_cart</Icon> : null}
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            ) : (
                                <Tooltip title="New Blooming Marketplace">
                                    {this.state.currentCompanyId ? <Button className='ml-8' variant='contained' color='secondary' onClick={this.navigateToLogout}>Continue to shop</Button> : <IconButton
                                        onClick={this.handlePilotShopClick}
                                        className={ShopButtonClass + " m-8"}>
                                        {this.state.changingEcommerceCustomerCode ?
                                            <CircularProgress size={24} color='primary' /> : (
                                                !customerData.inetCheck ?
                                                    <Icon className="text-16" color="action">not_interested</Icon> :
                                                    customerData.inetCheck && customerData.levStop ?
                                                        <Icon className="text-16" color="action">lock</Icon> :
                                                        customerData.inetCheck && !customerData.levStop ?
                                                            <Icon className="text-16"
                                                                color="action">shopping_cart</Icon> : null
                                            )}
                                    </IconButton>}
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    : null
                }
                    content={
                        <FuseAnimateGroup className="p-12"
                            enter={{
                                animation: "transition.slideUpBigIn"
                            }}>
                            {this.props.error ? <Alert severity="error">Either the customer does not exist or does not belong to your current debiteur group. Try switching to a different debiteur group</Alert> :
                                <div className="flex flex-wrap-reverse">
                                    <div className="w-full sm:w-full md:w-1/2 lg:w-1/2">
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1" elevation={1} style={{ height: '370px' }}>
                                                <div className="flex flex-wrap items-center px-16 h-64 border-b-1">
                                                    <div className="flex w-full sm:w-full md:w-full lg:w-1/4">
                                                        <Typography className="text-16">{t("TURNOVER")} : &nbsp;&nbsp;</Typography>
                                                    </div>
                                                    <div className="flex w-full sm:w-full md:w-full lg:w-3/4 text-center justify-between">
                                                        {/* <Mobile><Typography className="text-16">{t("TURNOVER")}: &nbsp;&nbsp;</Typography></Mobile> */}
                                                        {/* &nbsp;&nbsp;/&nbsp;&nbsp; */}
                                                        <div>
                                                            <span className={[classes.turnoverBadge, classes.currentYearBackground].join(' ')}>{(new Date().getFullYear())}</span>
                                                            &nbsp;&nbsp;&euro; {debiteurTurnover.currentYear && debiteurTurnover.currentYear.length > 0 ?
                                                                debiteurTurnover.currentYear.reduce((total, sum) => total + sum).toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}&nbsp;&nbsp;
                                                        </div>
                                                        <div>
                                                            <Tooltip placement="top" title={t('turnover_same_period')}>
                                                                <span>
                                                                    <span className={[classes.turnoverBadge, classes.lastYearBackground].join(' ')}>{(new Date().getFullYear() - 1)}</span>
                                                                    &nbsp;&nbsp;&euro;
                                                                </span>
                                                            </Tooltip>
                                                            {debiteurTurnover.lastYear && debiteurTurnover.lastYear.length > 0 ? (
                                                                getCompany() === 4 ? debiteurTurnover.lastYear.reduce((total, sum) => total + sum).toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    : debiteurTurnover.lastYear.filter((el, i) => i <= currentMonth).reduce((total, sum) => total + sum).toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })) : 0}&nbsp;&nbsp;</div>
                                                        {
                                                            getCompany() === 4 ?
                                                                <>
                                                                    <div >
                                                                        <span className={[classes.turnoverBadge, classes.reservationsBackground].join(' ')}>RSV</span>&nbsp;&nbsp;&euro; {debiteurTurnover.reservations && debiteurTurnover.reservations.length > 0 ?
                                                                            // numberToLocale(DebiteurTurnover.reservations.reduce((total, sum) => total + sum).toFixed(0)) : 0}</div>
                                                                            debiteurTurnover.reservations.reduce((total, sum) => total + sum).toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}&nbsp;&nbsp;
                                                                    </div>
                                                                    <div>
                                                                        <span className={[classes.turnoverBadge, classes.nextYearReservationsBackground].join(' ')}>{(new Date().getFullYear() + 1)}</span>&nbsp;&nbsp;&euro; {debiteurTurnover.nextYearReservations && debiteurTurnover.nextYearReservations.length > 0 ?
                                                                            // numberToLocale(DebiteurTurnover.reservations.reduce((total, sum) => total + sum).toFixed(0)) : 0}</div>
                                                                            debiteurTurnover.nextYearReservations.reduce((total, sum) => total + sum).toLocaleString('nl-NL', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : 0}&nbsp;&nbsp;
                                                                    </div>
                                                                </>
                                                                : null

                                                        }
                                                    </div>
                                                </div>
                                                {debiteurTurnover ? <div className="p-12" style={{ height: '300px' }}><LineChart labels={labels} datasets={turnoverDatasets} height={null} /></div> : null}
                                            </Paper>
                                        </div>
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1 p-12" elevation={1}>
                                                {this.props.departure.history ? <LineChart labels={this.props.departure.history.chartLabel} datasets={departureDataset} height={330} maintainAspectRatio={false} /> : null}
                                            </Paper>
                                        </div>
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1" elevation={1}>
                                                {this.props.departure.previousDays ? departureTable : null}
                                            </Paper>
                                        </div>
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1" elevation={1}>
                                                <Scrollbars className="rct-scroll" autoHeight autoHeightMin={200} autoHeightMax={650}>
                                                    {this.props.invoices.length > 0 ? table : <Typography className="text-center md:mt-32" component="h2" variant="h5">No invoices available</Typography>}
                                                </Scrollbars>
                                            </Paper>
                                        </div>
                                    </div>
                                    <div className="w-full sm:w-full md:w-1/2 lg:w-1/2" style={{ height: 'auto' }}>
                                        {checkRole('iCRMManager') ?
                                            <>
                                                <div className="p-12">
                                                    <Card className="w-full rounded-8 shadow-none border-1">
                                                        <div className="flex p-12">
                                                            <div className="min-w-48 pt-20">
                                                                <Tooltip title={t("OPENROUTE")}>
                                                                    <Icon color="action">access_time</Icon>
                                                                </Tooltip>
                                                            </div>
                                                            <FormControl variant="outlined" fullWidth>
                                                                <CustomerRouteCalendar
                                                                    routeDate={this.state.routeDate}
                                                                    handleRouteDateChange={this.handleRouteDateChange}
                                                                    onSubmitOpenCustomerRoute={this.onSubmitOpenCustomerRoute}
                                                                    label={t('OPENROUTE')}
                                                                    submittedRoute={this.state.submittedRoute}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </Card>
                                                </div> </> : null}
                                        <div className="p-12">
                                            <Card className="w-full rounded-8 shadow-none border-1">
                                                {customer}
                                            </Card>
                                        </div>
                                        <div className="flex flex-wrap w-full">
                                            <div className={`${blocksWidth} sm:w-full md:${blocksWidth} p-12`}>
                                                {getCompany() === 4 ?
                                                    <>
                                                        {/* <ListItem
                                        button
                                        onClick={this.handleDownloadReservationExcel}
                                        >
                                        <ListItemIcon><Tooltip placement="left" title={t('DOWNLOADBACKORDERS')}><Icon>save_alt</Icon></Tooltip></ListItemIcon>
                                        <ListItemText primary={t('DOWNLOADBACKORDERS')} />
                                    </ListItem>
                                    <Divider /> */}
                                                        <Tooltip title={this.props.todaysTurnover && this.props.todaysTurnover > 0 ? t('show_backorders') : ''}>

                                                            <Paper className={classes.orangeBackground + " w-full rounded-8 shadow-none border-1 text-white"}
                                                                elevation={1}
                                                                onClick={this.props.todaysTurnover && this.props.todaysTurnover > 0 ? this.handleBackoverviewOpen : null}
                                                                style={{ height: '100px', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
                                                                <h3>&euro; {this.props.todaysTurnover ? this.props.todaysTurnover.toFixed(2) : 0}</h3>
                                                                <small className="text-white">Back orders</small>
                                                            </Paper>
                                                            {/* <Paper className={classes.orangeBackground + " w-full rounded-8 shadow-none border-1 text-white"} 
                                        elevation={1} 
                                        onClick={this.handleDownloadReservationExcel}
                                        style={{height: '100px', padding: '20px', textAlign: 'center', cursor: 'pointer'}}>
                                        <h3>&euro; {reservationsTotal ? numberToLocale(reservationsTotal) : 0 }</h3>
                                        <small className="text-white">Reservations</small>
                                    </Paper> */}
                                                        </Tooltip>
                                                    </>
                                                    :
                                                    <Paper className={classes.orangeBackground + " w-full rounded-8 shadow-none border-1 text-white"} elevation={1} style={{ height: '100px', padding: '20px', textAlign: 'center' }}>
                                                        <h3>&euro; {customerData.verkoopBedrag ? customerData.verkoopBedrag.toFixed(2) : 0}</h3>
                                                        <small className="text-white">{t('DEPARTURE')} {t('TODAY')}</small>
                                                    </Paper>
                                                }
                                            </div>
                                            <div className={`${blocksWidth} sm:w-full md:${blocksWidth} p-12`}>
                                                <Paper className="w-full rounded-8 shadow-none border-1" elevation={1} style={{ height: '100px', padding: '20px', textAlign: 'center' }}>
                                                    <h3 className="text-secondary">&euro; {this.props.unpaid}</h3>
                                                    <small className="text-secondary">{t('UNPAID')}</small>
                                                </Paper>
                                            </div>
                                            {customerData.credit && customerData.factTotaal !== 0 ?
                                                <div className={`${blocksWidth} sm:w-full md:${blocksWidth} p-12`}>
                                                    <Paper className="w-full rounded-8 shadow-none border-1 p-8" elevation={1} style={{ height: '100px', textAlign: 'center' }}>
                                                        <CircularProgress variant="determinate" color="secondary" value={(-customerData.credit / (customerData.factTotaal - customerData.credit)) * 1000} />
                                                        <br />
                                                        <strong>{((customerData.credit / (customerData.factTotaal - customerData.credit)) * 100).toFixed(1)}</strong>&#37; Credits
                                                        <p><strong>&euro;{customerData.credit.toFixed(2)}</strong></p>
                                                    </Paper>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="p-12">
                                            <Paper className="w-full rounded-8 shadow-none border-1 p-12" elevation={1}>
                                                {this.props.weblogVisits.length > 0 ? <CustomerWeblogVisits weblogVisits={this.props.weblogVisits} themeColors={themeColors} /> : null}
                                            </Paper>
                                        </div>
                                        <div className="p-12">
                                            <Card className="p-24 w-full rounded-8 shadow-none border-1" color="default">
                                                {this.state.comment.sent ? <Alert className="mb-12" variant="outlined" severity="success">Comment sent successfully!</Alert> : null}
                                                <h4>{t("ADDMESSAGE")}</h4>
                                                <div className="flex">
                                                    <div className="w-1/2 p-6">
                                                        <TextField
                                                            name="title"
                                                            value={this.state.comment.title}
                                                            onChange={this.updateComment}
                                                            label="Title"
                                                            style={{ marginLeft: "5px", marginRight: "5px", width: "100%" }}
                                                            margin="normal"
                                                        />
                                                    </div>
                                                    <div className="w-1/2 p-6" style={{ float: 'right' }}>
                                                        <FormControl style={{ marginTop: '16px' }} fullWidth>
                                                            <InputLabel htmlFor="age-simple">Type</InputLabel>
                                                            <Select
                                                                name="type"
                                                                value={this.state.comment.type}
                                                                onChange={this.updateComment}
                                                                inputProps={{
                                                                    name: 'type',
                                                                    id: 'type',
                                                                }}
                                                            >
                                                                <MenuItem value={1}>{t('CALL')}</MenuItem>
                                                                <MenuItem value={2}>{t('VISIT')}</MenuItem>
                                                                <MenuItem value={3}>{t('DELIVERY')}</MenuItem>
                                                                <MenuItem value={4}>{t('FILLING')}</MenuItem>
                                                                {
                                                                    getCompany() === 4 ?
                                                                        (
                                                                            [
                                                                                <MenuItem key={5} value={5}>Beurs</MenuItem>,
                                                                                <MenuItem key={6} value={6}>Mail</MenuItem>,
                                                                                <MenuItem key={7} value={7}>Webshop</MenuItem>
                                                                            ]
                                                                        ) : null
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                                <TextField
                                                    id="memo"
                                                    name="memo"
                                                    value={this.state.comment.memo}
                                                    onChange={this.updateComment}
                                                    label={t("MESSAGE")}
                                                    className="w-full p-6"
                                                    margin="normal"
                                                />
                                                <Button variant="contained" className="mt-12" color="primary" onClick={this.handleCommentSubmit}>{t('SEND')}</Button>
                                            </Card>
                                        </div>
                                        <div className="p-12">
                                            <Paper className="p-12 w-full rounded-8 shadow-none border-1">
                                                <List className="w-full">
                                                    {this.props.comments.length > 0 ? this.props.comments.map((comment, i) => {
                                                        let icon = null;
                                                        if (comment.type === '1') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">phone</Icon></span>
                                                        } else if (comment.type === '2') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">person_pin_circle</Icon></span>
                                                        } else if (comment.type === '3') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">local_shipping</Icon></span>
                                                        } else if (comment.type === '4') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">payment</Icon></span>
                                                        } else if (comment.type === '5') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">meeting_room</Icon></span>
                                                        } else if (comment.type === '6') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">mail_outline</Icon></span>
                                                        } else if (comment.type === '7') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">shopping_cart</Icon></span>
                                                        } else if (comment.type === '8') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">person_add</Icon></span>
                                                        } else if (comment.type === '9') {
                                                            icon = <span className="ml-16">{comment.title}<Icon className="ml-12 float-right" fontSize="small">assignment</Icon></span>
                                                        }
                                                        return (
                                                            <ListItem key={i} alignItems="flex-start">
                                                                <ListItemAvatar>
                                                                    <Avatar className={classes.blueBackground}>{comment.name.substring(0, 1)}</Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={icon}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography component="span" className={classes.inline + ' ml-16'} style={{ fontSize: '13px' }} color="textPrimary">
                                                                                {comment.memo}
                                                                            </Typography>
                                                                            &mdash; {comment.name}{comment.regdat.length > 0 ? ', ' + moment(new Date(comment.regdat * 1000)).format('YYYY-MM-DD HH:mm') : null}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </ListItem>
                                                        )
                                                    }) : <Typography className="text-center" component="h2" variant="h5">{t('NOCOMMENTS')}</Typography>
                                                    }
                                                </List>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <div className="flex flex-wrap">
                        <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                        </div>
                        <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12" style={{maxHeight: '280px'}}>
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                        </div>
                        <div className="widget flex w-full sm:w-full md:w-1/2 lg:w-1/2 p-12">
                        </div>
                    </div> */}
                        </FuseAnimateGroup>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = ({ customer, hbxdeco }) => {
    return {
        customer: customer.customer,
        turnover: customer.turnover,
        departure: customer.departure,
        invoices: customer.invoices,
        todaysTurnover: customer.todaysTurnover,
        unpaid: customer.unpaid,
        comments: customer.comments,
        submitted: customer.submitted,
        weblogVisits: customer.weblogVisits,

        decoDebiteurTurnover: hbxdeco.debiteurTurnover,

        error: customer.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCustomer: (debnr) => dispatch(actions.fetchDbCustomer(debnr)),
        onFetchTurnover: (debnr) => dispatch(actions.fetchDbCustomerTurnover(debnr)),
        onFetchDeparture: (debnr) => dispatch(actions.fetchDbCustomerDeparture(debnr)),
        onFetchInvoices: (debnr) => dispatch(actions.fetchDbCustomerInvoices(debnr)),
        onFetchTodaysTurnover: (debnr) => dispatch(actions.fetchDbCustomerTodaysTurnover(debnr)),
        onFetchUnpaid: (debnr) => dispatch(actions.fetchDbCustomerUnpaid(debnr)),
        onFetchWeblogVisits: (debnr) => dispatch(actions.fetchCustomerWeblogVisits(debnr)),
        onFetchComments: (debnr) => dispatch(actions.fetchDbCustomerComments(debnr)),
        onSubmitComment: (debnr, comment) => dispatch(actions.submitDbCustomerComment(debnr, comment)),
        onSubmitOpenOrClose: (debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, email, name) =>
            dispatch(actions.submitOpenOrCloseCustomer(debnr, levStop, shouldUpdateAllInFinancialGroup, finDebnr, email, name)),
        onSubmitOpenCustomerRoute: (debnr, datum, callback) => dispatch(actions.openCustomerRoute(debnr, datum, callback)),
        onDownloadReservationExcel: (debnr) => dispatch(actions.downloadReservationExcel(debnr)),
        onMailReservationExcel: (body, mailSalesmanOnly) => dispatch(actions.mailReservationExcel(body, mailSalesmanOnly)),
        onFetchHbxDecoDebiteurTurnover: (debnr) => dispatch(decoActions.fetchHbxDecoDebiteurTurnover(debnr)),
        onCreateUserLog: (userLog) => dispatch(actions.createUserLog(userLog))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(withTranslation()(Customer))));