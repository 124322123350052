import React, { Component } from 'react';
import {withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import {Icon, Typography, Tooltip, TextField, Card, CardContent, CardHeader, Button, Badge, LinearProgress, Box, IconButton} from '@material-ui/core';
import Alert from '../../../components/UI/Alert/Alert';
import ReactTable from "react-table";
import {connect} from 'react-redux';
import * as actions from '../../../store/api/actions';
import moment from 'moment';
import _, { curry } from 'lodash';
import axios from 'axios';

const styles = theme => ({
    layoutRoot: {},
    datePicker: {
        // minWidth: '155px',
        width: '100%',
    },
    floatRight: {
        float: 'right',
        marginLeft: '4px',
    },
    cardHeader: {
        backgroundColor: '#f6f8f8',
        borderBottom: '1px solid',
        borderColor: '#dee5e7 !important;'
    },
    successButton: {
        color: '#27c24c !important'
    },
});

var invoice = {
    "date": null,
    "currency": null,
    // "exchangeRate": 10.25,
    "netAmount": null,
    // "netAmountInBaseCurrency": 0.00,
    // "grossAmount": 12.50,
    // "marginInBaseCurrency": -46.93,
    // "marginPercentage": 0.0,
    "vatAmount": null,
    "paymentTerms": {
        "paymentTermsNumber": 1,
        "daysOfCredit": 14,
        "name": "Lobende maned 14 dage",
        "paymentTermsType": "invoiceMonth"
    },
    "customer": {
        "customerNumber": null
    },
    "recipient": {
        "name": "Toj & Co Grossisten",
        "address": "Vejlevej 21",
        "zip": "7000",
        "city": "Fredericia",
        "vatZone": {
            "name": "Domestic",
            "vatZoneNumber": 1,
            "enabledForCustomer": true,
            "enabledForSupplier": true
        }
    },
    "references": {
        "other": null
    },
    "layout": {
        "layoutNumber": 5
    },
    "lines": [
        {
            "lineNumber": 1,
            "sortKey": 1,
            "unit": {
                "unitNumber": 2,
                "name": "Tim"
            },
            "product": {
                "productNumber": "50"
            },
            "quantity": 1.00,
            "unitNetPrice": 10.00,
            "discountPercentage": 0.00,
            "unitCostPrice": 46.93,
            "totalNetAmount": 10.00,
            "marginInBaseCurrency": -46.93,
            "marginPercentage": 0.0
        },
        {
            "lineNumber": 1,
            "sortKey": 1,
            "unit": {
                "unitNumber": 1,
                "name": "stk."
            },
            "product": {
                "productNumber": "50"
            },
            "quantity": 1.00,
            "unitNetPrice": 10.00,
            "discountPercentage": 0.00,
            "unitCostPrice": 46.93,
            "totalNetAmount": 10.00,
            "marginInBaseCurrency": -46.93,
            "marginPercentage": 0.0
        },
        {
            "lineNumber": 1,
            "sortKey": 1,
            "unit": {
                "unitNumber": 4
            },
            "product": {
                "productNumber": "50"
            },
            "quantity": 1.00,
            "unitNetPrice": 10.00,
            "discountPercentage": 0.00,
            "unitCostPrice": 46.93,
            "totalNetAmount": 10.00,
            "marginInBaseCurrency": -46.93,
            "marginPercentage": 0.0
        }
    ]
};

class Vismaexport extends Component {

    state = {
        fromdate: new Date().toISOString().slice(0, 10),
        todate: new Date().toISOString().slice(0, 10),
        startLoading: false,
        finishedLoading: false,
        percentage: 0,
        AllCustomers: [],
        AllOrders: [],
        AllVismaCustomers: [],
        clickedOrdersSuccess: [],
        clickedOrdersError: [],
        ApiResponse: [],
        ApiStatus: 0,
        ApiCreatedId: 0,
    }

    componentDidMount() {
        this.handleDateButtonClick('defaultdate');
    }
    
    handleFromDate = event => {
        this.setState({fromdate: event.target.value})
    }
    
    handleToDate = event => {
        this.setState({todate: event.target.value})
    }

    handleGenerateClick = () => {
        this.setState({finishedLoading: false, startLoading: true, percentage: 0});
        this.GetVismaExportPreview();
    }

    finishedLoading = () => {
        this.setState({finishedLoading: true, startLoading: false, percentage: 100});
    }

    applyDates = () => {
        const startDate = moment(this.state.startDate).format('YYYY-MM-DD');
        const endDate = moment(this.state.endDate).format('YYYY-MM-DD');
        this.setState({fromdate: startDate, todate: endDate}, () => this.handleGenerateClick())
    }

    handleDateButtonClick = buttonClicked => {
        let startDate = new Date();
        let endDate = new Date();
        switch(buttonClicked) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                endDate.setDate(endDate.getDate() - 1);
                break;
            case 'thisweek': 
                startDate = this.getMonday(startDate);
                break;
            case 'thismonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                break;
            case 'thisyear': 
                startDate = new Date(startDate.getFullYear(), 0, 1)
                break;
            case 'lastweek': 
                startDate.setDate(startDate.getDate()-7);
                startDate = this.getMonday(startDate);
                endDate = moment(startDate).add('days', 4).toDate();
                break;
            case 'lastmonth': 
                startDate = new Date(startDate.getFullYear(), startDate.getMonth()-1, 1);
                // startDate.setHours(-1);
                // startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
                // startDate.setHours(-24);
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
                endDate.setHours(-4);
                break;
            case 'lastyear': 
                startDate = new Date(startDate.getFullYear() - 1, 0, 1);
                endDate = new Date(endDate.getFullYear() - 1, 11, 31);
                break;
            case 'defaultdate': 
                startDate.setDate(startDate.getDate());
                endDate.setDate(endDate.getDate());
                break;
            default: break;
        }
        this.setState({startDate, endDate}, this.applyDates);        
    }

    getMonday = date => {
        let day = date.getDay() || 7;
        if( day !== 1 ) 
            date.setHours(-24 * (day - 1)); 
        return date;
    }

    GetVismaExportPreview = () => {
        // this.setState({specialOfferSent: specialOffer});
        axios.get('GetVismaExportCustomers?fromdate=' + this.state.fromdate + '&todate=' + this.state.todate
          ).then(res => {
            this.setState({AllCustomers: res.data});
            // console.log(res.data);
        }).then(
            axios.get('GetVismaExportOrders?fromdate=' + this.state.fromdate + '&todate=' + this.state.todate
            ).then(res => {
                this.setState({AllOrders: res.data});
            }).then(
                this.finishedLoading()
            )
        );
        
    }

    SendToVismaAPIDraftInvoice = row => {
        var lines2 = (this.state.AllOrders).filter(u => u.factnr === row.factnr).map(order => ({
                lineNumber: 1,
                sortKey: 1,
                description: order.arttxt,
                unit: {unitNumber: 1, name: order.arttxt},
                product: {productNumber: "1"},
                quantity: order.levTotaal,
                unitNetPrice: order.unitNetPrice,
                totalNetAmount: order.totalNetAmount,
            }));

        var newInvoice = {
            ...invoice,
            date: row.fctdat,
            currency: row.currency,
            netAmount: row.netAmount,
            vatAmount: row.vatAmount,
            paymentTerms: {
                paymentTermsNumber: 6, daysOfCredit : row.daysOfCredit, name: "Netto 30 dage", paymentTermsType: "net"
            },
            notes: {heading: row.ordnr},
            customer: {customerNumber: Number(row.findebnr)},
            recipient: {name: row.name, address: row.address, zip: row.zip, city: row.city, 
                vatZone: {vatZoneNumber: 2}
            },
            references: {other: row.factnr},
            layout: {layoutNumber: 19},
            lines: lines2,
         }


        //console.log(newInvoice);
        axios.post('/CreateVismaDraftInvoice', newInvoice
            ).then(res => {
                console.log(res);
                this.setState({ApiResponse: res.data.message, ApiStatus: res.data.httpStatusCode, ApiCreatedId: res.data.draftInvoiceNumber});
                if(this.state.ApiCreatedId > 0){
                    this.setState({ApiResponse: "Draft invoice created", ApiStatus: res.status});
                    this.setState({clickedOrdersSuccess: [...this.state.clickedOrdersSuccess, row.debnr + row.factnr]});
                }
                else {
                    this.setState({clickedOrdersError: [...this.state.clickedOrdersError, row.debnr + row.factnr]});
                }
            }).catch((error) => {
                // Error
                if (error.response) {
                    this.setState({ApiResponse: error.response.data.message, ApiStatus: error.response.status});
                    this.setState({clickedOrdersError: [...this.state.clickedOrdersError, row.debnr + row.factnr]});
                } else if (error.request) {
                    // console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.response);
            });

    }

    render() {
        const {classes, t} = this.props;
        
        const debnrListObject = _.groupBy(this.state.AllCustomers, 'debnr');
        // console.log(this.state.clickedOrders);
        const debnrList = Object.keys(debnrListObject).map(debnr => ({ debnr, count: debnrListObject[debnr].length, synced: false})).filter(u => u.debnr.length > 3);
    
        // console.log("start loading : " + this.state.startLoading);
        //console.log("Visma customers : " + this.state.AllVismaCustomers);

        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">API's</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">Visma Invoice Export</Typography>
                            </div>
                            <Typography variant="h6">Visma Invoice Export</Typography>
                         </div>
                      </div>
                }
                content={
                        <div>
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >

                            <div className="flex flex-wrap w-full p-12">
                                <div className="flex w-full p-12 md:w-1/3">
                                    <Card className="w-full">
                                        <CardHeader 
                                        className={classes.cardHeader}
                                        title={
                                            <>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                Choose your period
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                            <b>From</b> {this.state.fromdate} <b>until</b> {this.state.todate} 
                                            {this.state.AllCustomers.length > 0 ? " Rows found : " + this.state.AllCustomers.length : null}
                                            </Typography>
                                            </>
                                        } />
                                        <CardContent>
                            <div className="flex flex-wrap w-full justify-center p-6" elevation={1}>
                                <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('yesterday')}>
                                    {t("YESTERDAY")}
                                </Button>
                                <Button variant="contained" color="primary" className="m-12" onClick={() => this.handleDateButtonClick('defaultdate')}>
                                    {t("TODAY")}
                                </Button>
                            </div>
                                            <TextField
                                                id="fromdate"
                                                label= {t("STARTDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.fromdate}
                                                // defaultValue={this.state.fromdate}
                                                onChange={this.handleFromDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                id="todate"
                                                label= {t("ENDDATE") + " " + t("DATE")}
                                                type="date"
                                                value={this.state.todate}
                                                // defaultValue={this.state.todate}
                                                onChange={this.handleToDate}
                                                className={classes.datePicker}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }} 
                                            />
                                            <br />
                                            <br />
                                            <div className="w-full flex flex-wrap">
                                                <div className="w-full xs:w-full sm:w-full md:w-full lg:w-full p-6">
                                                <Button 
                                                    variant="contained" 
                                                    color="primary" 
                                                    fullWidth 
                                                    startIcon={<Icon>autorenew</Icon>}
                                                    onClick={this.handleGenerateClick}>
                                                    {/* <Icon className={"m-10"}>autorenew</Icon>  */}
                                                    {t("PREVIEW")}
                                                </Button>
                                                </div>
                                                <div className="w-full xs:w-full sm:w-full md:w-full lg:w-full p-6">
                                                
                                                <Tooltip title={debnrList.length + " rows ready to export"}>
                                                    <Button 
                                                        variant="contained"
                                                        color="secondary"
                                                        // className="w-full"
                                                        fullWidth
                                                        startIcon={<Icon>save_alt</Icon>}
                                                        onClick={() => this.SendToVismaAPIDraftInvoice(debnrList)}>
                                                        Sync All
                                                    </Button>
                                                </Tooltip>
                                                </div>
                                            </div>
                                            {this.state.ApiStatus > 350 && this.state.ApiStatus > 0 ? <Alert title={this.state.ApiResponse} status="AlertDanger" className="mt-12" /> : null}
                                            {this.state.ApiStatus < 350 && this.state.ApiStatus > 0 ? <Alert title={this.state.ApiResponse + " : " + this.state.ApiCreatedId} status="AlertSuccess" className="mt-12" /> : null}

                                            {this.state.startLoading ? <>
                                            <Box display="flex" alignItems="center">
                                                <Box width="100%" mr={1}>
                                                    <LinearProgress color="primary" className="mb-12" />
                                                </Box>
                                            </Box>
                                            </> : this.props.JDFexporterror}
                                        </CardContent>
                                    </Card>
                                </div>
                                <div className="flex w-full p-12 md:w-2/3">
                                    <Card className="w-full">
                                            <ReactTable
                                                style={{border: '0px'}}
                                                className="-striped -highlight border-0"
                                                data={this.state.AllCustomers}
                                                columns={[
                                                    {
                                                        Header    : "Synced",
                                                        accessor  : "Synced",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 80,
                                                        Cell : row => this.state.clickedOrdersSuccess.includes(row.original.debnr + row.original.factnr) ? 
                                                        <Icon color="action" style={{color: 'green'}}>check_circle</Icon>
                                                        :
                                                            this.state.clickedOrdersError.includes(row.original.debnr + row.original.factnr) ? 
                                                            
                                                        <Tooltip title={"Tro to sync again"}>
                                                            <Icon color="action" style={{color: 'red', cursor: 'pointer'}} onClick={() => this.SendToVismaAPIDraftInvoice(row.original)}>sync</Icon>
                                                        </Tooltip> :

                                                        <Tooltip title={"Click to sync"}>
                                                            <Icon color="action"  style={{cursor: 'pointer'}} onClick={() => this.SendToVismaAPIDraftInvoice(row.original)}>check_circle</Icon>
                                                        </Tooltip>
                                                    },
                                                    {
                                                        Header    : "Debnr",
                                                        accessor  : "debnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : "FinDebnr",
                                                        accessor  : "findebnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        // width: 100,
                                                    },
                                                    {
                                                        Header    : "Name",
                                                        accessor  : "name",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 300,
                                                    },
                                                    {
                                                        Header    : "Faktura Nr.",
                                                        accessor  : "factnr",
                                                        filterable: true,
                                                        // Style: "text-align: right;",
                                                        className: "justify-center",
                                                        width: 100,
                                                    },
                                                    {
                                                        Header    : "Export",
                                                        className: "justify-center",
                                                        width: 70,
                                                        Cell: row =>
                                                        <IconButton aria-label="Edit" onClick={() => this.SendToVismaAPIDraftInvoice(row.original)}>
                                                            <Icon>send</Icon>
                                                        </IconButton>
                                                    },
                                                ]}
                                                pageSizeOptions={[10, 50, 100]}
                                                pageSize={100}
                                                noDataText="No customers found"
                                            />
                                    </Card>
                                </div>
                            </div>
                                </FuseAnimateGroup>
                            </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        JDFexporterror: state.api.error,
        // loading: state.api.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGenerateExport: (fromdate, todate) => dispatch(actions.generateJDFexport(fromdate, todate)),
        onDownloadExcel: (fromdate, todate, type) => dispatch(actions.downloadExcelJDFexport(fromdate, todate, type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles, {withTheme: true})(withTranslation()(Vismaexport)));